import {
  ROLE_CATALOG_READ_ONLY_USER,
  ROLE_ROOT_USER,
  ROLE_STANDARD_USER,
  ROLE_ADMIN_USER,
} from './roles';

export const HeaderTabs = [
  {
    key: '/batch',
    label: 'Batch Management',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER, ROLE_STANDARD_USER],
  },
  {
    key: '/catalog',
    label: 'Catalog Management',
    allowedRoles: [
      ROLE_ADMIN_USER,
      ROLE_ROOT_USER,
      ROLE_STANDARD_USER,
      ROLE_CATALOG_READ_ONLY_USER,
    ],
  },
  {
    key: '/categories',
    label: 'Categories',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER, ROLE_STANDARD_USER],
  },
  {
    key: '/manufacturers',
    label: 'Manufacturers',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER, ROLE_STANDARD_USER],
  },
  {
    key: '/users',
    label: 'User Management',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER],
  },
  {
    key: '/system/general',
    label: 'System Configuration',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER, ROLE_STANDARD_USER],
  },
  {
    key: '/maintenance',
    label: 'Maintenance',
    allowedRoles: [ROLE_ADMIN_USER, ROLE_ROOT_USER, ROLE_STANDARD_USER],
  },
];
