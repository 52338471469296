import React from 'react';
import { EditCatalogCommonTabs } from './CommonTabChildren';
import { ROLE_CATALOG_READ_ONLY_USER } from '../../../../constants/roles';
import { Switch, Table, Tooltip, Typography } from 'antd';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import { _get } from '../../../../utils/lodashUtils';
import { DESCRIPTION_PARAGRAPH_ELLIPSIS } from '../../../../constants/hardData';
import get from 'lodash/get';
import { copyToClipboard } from '../../../../utils/common';

export const EditCatalogPricingTab = (props) => {
  const {
    apiLoader,
    connectorData,
    roleCode,
    toggleEditConnectorModal,
    laborData,
    setShowPartError,
    calculateValuesForItemPriceForm,
    updateConnectorsState,
  } = props;

  const tableColumns = () => {
    const columns = [
      {
        title: 'Edit',
        dataIndex: 'id',
        key: 'id',
        width: 70,
        render: (value, record) =>
          roleCode === ROLE_CATALOG_READ_ONLY_USER ? (
            <Tooltip overlayClassName='tooltip' title={'View'}>
              <EyeOutlined onClick={() => toggleEditConnectorModal(record)} />
            </Tooltip>
          ) : (
            <Tooltip
              overlayClassName='tooltip'
              title={
                _get(record, 'SyncConnector.require_labor') &&
                _get(laborData.data[0], 'value') === null
                  ? 'Labor Hours should be 0 or more'
                  : null
              }
            >
              <FormOutlined
                disabled={
                  _get(record, 'SyncConnector.require_labor') &&
                  _get(laborData.data[0], 'value') === null
                }
                onClick={() => {
                  if (
                    _get(record, 'SyncConnector.require_labor') &&
                    _get(laborData.data[0], 'value') === null
                  )
                    return;
                  toggleEditConnectorModal(record);
                  setShowPartError(
                    !get(record, 'connector_part_number') &&
                      get(
                        record,
                        'SyncConnector.SyncConnectorType.max_spn_length'
                      ) < get(record, 'connector_part_number')?.length
                  );
                  calculateValuesForItemPriceForm(1000, 'markUpPercent');
                }}
              />
            </Tooltip>
          ),
      },
      {
        title: 'Active',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 100,
        render: (value, record) => (
          <Tooltip
            overlayClassName='tooltip'
            title={
              _get(record, 'SyncConnector.require_labor') &&
              _get(laborData.data[0], 'value') === null
                ? 'Labor Hours should be 0 or more'
                : null
            }
          >
            <Switch
              checked={value}
              key={record.id}
              disabled={
                roleCode === ROLE_CATALOG_READ_ONLY_USER ||
                (_get(record, 'SyncConnector.require_labor') &&
                  _get(laborData.data[0], 'value') === null)
              }
              onChange={(value) => {
                setShowPartError(
                  get(
                    record,
                    'SyncConnector.SyncConnectorType.max_spn_length'
                  ) < get(record, 'connector_part_number')?.length
                );
                updateConnectorsState(
                  {
                    connectors: [
                      {
                        item_master_sync_connector_id: record.sync_connector_id,
                        is_active: value,
                      },
                    ],
                  },
                  value,
                  record
                );
              }}
            />
          </Tooltip>
        ),
      },
      {
        title: 'Connectors',
        key: 'sync_connector_id',
        dataIndex: 'sync_connector_id',
        width: 200,
        render: (value, record) => _get(record, 'SyncConnector.name'),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: 100,
        align: 'right',
        render: (value) => (value ? value.toFixed(2) : '-'),
      },
      {
        title: 'Connector Part No.',
        key: 'connector_part_no',
        width: 240,
        ellipsis: true,
        dataIndex: 'connector_part_number',
        render: (value) => (
          <Tooltip overlayClassName='tooltip' title={value}>
            <div
              style={{
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                wordBreak: 'keep-all',
                cursor: 'pointer',
              }}
              onClick={() => {
                copyToClipboard(value, 'CPN copied to the clipboard.');
              }}
            >
              {value}
            </div>
          </Tooltip>
        ),
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        render: (value, record) =>
          value ? (
            <Typography.Paragraph
              ellipsis={{
                ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                tooltip: value,
              }}
              key={record.id}
            >
              {value}
            </Typography.Paragraph>
          ) : (
            '-'
          ),
      },
    ];
    return columns;
  };

  return (
    <EditCatalogCommonTabs title='Pricing'>
      <Table
        loading={apiLoader}
        pagination={false}
        dataSource={_get(connectorData, 'data', [])}
        size={'small'}
        rowKey={'id'}
        scroll={{ y: 300 }}
        columns={tableColumns()}
      />
    </EditCatalogCommonTabs>
  );
};
