import { _indexOf } from './lodashUtils';

export const hasPermission = (master, useRole) => {
  let allow = false;

  if (_indexOf(master, useRole) >= 0) {
    allow = true;
  }

  return allow;
};

export const generatePaginationObj = function (page = 1, limit = 10) {
  const offset = (page - 1) * limit;
  return {
    offset,
    limit,
  };
};

export const filterTreeData = (data, searchTerm, title) => {
  return data
    .map((node) => {
      const match = node[title]
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      if (match) return node;

      if (node.children) {
        const filteredChildren = filterTreeData(
          node.children,
          searchTerm,
          title
        );
        if (filteredChildren.length > 0)
          return { ...node, children: filteredChildren };
      }
      return null;
    })
    .filter(Boolean);
};
