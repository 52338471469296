import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  Tabs,
} from 'antd';
import { postAPI, putAPI } from '../../../utils/apiRequest';
import { interpolate } from '../../../utils/common';
import {
  CATALOG_CONNECTOR_UPDATE,
  CATALOG_DISTRIBUTOR_UPDATE,
  SET_CATALOG_PRIMARY_DISTRIBUTOR,
  UNLOCK_CONNECTOR,
} from '../../../constants/api';
import { _get } from '../../../utils/lodashUtils';
import { arrayMoveImmutable } from 'array-move';
import {
  REQUIRED_FIELD_WITH_NAME_MESSAGE,
  TYPE_ERROR,
} from '../../../constants/message';
import get from 'lodash/get';
import { ROLE_CATALOG_READ_ONLY_USER } from '../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../../redux/Slices/messageSlice';
import ModalCom from '../../common/Modal';
import {
  changeBadCpnModalName,
  changeBadCpnModalState,
  setBadCpnCallbackFuncName,
  setBadCpnCallbackFuncParams,
  setBadCpnDataEditCatalog,
} from '../../../redux/Slices/badCpnBatchesSlice';
import {
  BAD_CPN_CALLBACK_FUNC_NAMES,
  CATALOG,
  MANUFACTURER,
  MANUFACTURER_IN_USE_STATE,
  MANUFACTURER_MAPPED_STATE,
} from '../../../constants/constants';
import { getCatalogConnectorData } from '../../../redux/Slices/catalogConnectorSlice';
import {
  getEditCatalogDistributorData,
  getEditCatalogLaborData,
  setEditCatalogDistributorData,
  setEditCatalogLaborData,
} from '../../../redux/Slices/editCatalogTabSlice';
import { EditCatalogDistributorTab } from './editCatalogTabs/Distributor';
import { EditCatalogPricingTab } from './editCatalogTabs/Pricing';
import { EditCatalogLaborTab } from './editCatalogTabs/Labor';
import { isEmpty, lowerCase } from 'lodash';
import { useNavigate } from 'react-router';

function EditCatalogExtraData(props) {
  const { distributorTabKey, setDistributorTabKey } = props;
  const navigate = useNavigate();
  const costFormRefs = useRef(null);
  const itemPriceFormRefs = useRef(null);
  const itemDistributorFormRef = useRef(null);
  const [dataActionLoading, setDataActionLoading] = useState(false);
  const [editConnectorModal, toggleEditConnectorModal] = useState(null);
  const [editDistributorModal, toggleEditDistributorModal] = useState(null);
  const [showPartError, setShowPartError] = useState(null);

  const roleCode = useSelector((state) => _get(state, 'auth.Role.role_code'));
  const distributorData = useSelector((state) =>
    _get(state, 'editCatalogTabReducer.distributor')
  );
  const distributorPagination = useSelector(
    (state) => state.editCatalogTabReducer.distributorPagination
  );
  const laborData = useSelector((state) =>
    _get(state, 'editCatalogTabReducer.labor')
  );
  const connectorData = useSelector((state) =>
    _get(state, 'catalogConnectorReducer.data')
  );
  const apiLoader = useSelector((state) => _get(state, 'apiLoader.bool'));
  const dispatch = useDispatch();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        _get(distributorData, 'data', []).slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      dispatch(
        setEditCatalogDistributorData({ ...distributorData, data: newData })
      );
      if (newData.length) setPrimaryDistributor(newData[0].id);
    }
  };

  const loadCatalogDistributors = (
    filterValues,
    sorters = {},
    paginationVals
  ) => {
    dispatch(
      getEditCatalogDistributorData({
        filterValues: filterValues,
        sorters: sorters,
        id: props.id,
        paginationVals,
      })
    );
  };
  const loadCatalogLabor = () => {
    dispatch(getEditCatalogLaborData({ id: props.id }));
  };
  const updateLabor = (id, value, index) => {
    let tempLaborData = [...laborData.data];
    tempLaborData[index] = {
      ...tempLaborData[index],
      value: value,
    };
    dispatch(setEditCatalogLaborData({ ...laborData, data: tempLaborData }));
  };

  const loadCatalogConnector = () => {
    dispatch(getCatalogConnectorData(props.id));
  };

  const updateDistributorData = (data) => {
    setDataActionLoading(true);
    let successFn = function () {
      loadCatalogDistributors(
        { is_active: distributorTabKey === 'ACTIVE' },
        {},
        distributorPagination
      );
      toggleEditDistributorModal(null);
      props.loadCatalogDetailById(props.id);
    };
    let errorFn = function () {
      setDataActionLoading(false);
    };
    putAPI(
      interpolate(CATALOG_DISTRIBUTOR_UPDATE, [props.id]),
      { distributors: [{ ...data }] },
      successFn,
      errorFn
    );
  };

  const openPopupWindow = (popupUrl) => {
    const width = window.innerWidth * 0.8;
    const height = window.innerHeight * 0.8;

    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      popupUrl,
      'popup', // Window name
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
    );
  };

  const updateConnectorsState = (data, value, record) => {
    setDataActionLoading(true);
    let successFn = function (result) {
      setDataActionLoading(false);
      if (
        result.missing_manufacturer &&
        !isEmpty(get(result, 'missing_manufacturer'))
      ) {
        const params = new URLSearchParams();
        params.append('name', result.missing_manufacturer.name);
        params.append('id', result.missing_manufacturer.id);
        params.append('inuse', 'true');
        params.append('mappingstatus', 'all');
        const popupUrl = `${window.location.origin}/system/sync-connectors/${
          record.SyncConnector.SyncConnectorType.id
        }/${record.sync_connector_id}/integrations/${lowerCase(
          MANUFACTURER
        )}?${params.toString()}`;
        openPopupWindow(popupUrl);
      } else if (get(result, 'bad_item_ids').length) {
        let msg = '';
        result.bad_item_ids.forEach((item, index) => {
          if (index === 0) {
            msg = item.issue;
          } else {
            msg = msg + ' | ' + item.issue;
          }
        });
        dispatch(
          setMessage({
            type: TYPE_ERROR,
            msg: msg,
          })
        );
        if (record && value) {
          dispatch(changeBadCpnModalState(true));
          dispatch(changeBadCpnModalName(CATALOG));
          dispatch(setBadCpnDataEditCatalog(result, props.id));
          dispatch(
            setBadCpnCallbackFuncName(
              BAD_CPN_CALLBACK_FUNC_NAMES.GET_CATALOG_SYNC_CONNECTOR_DATA
            )
          );
          dispatch(setBadCpnCallbackFuncParams({}));
        }
      } else {
        toggleEditConnectorModal(null);
        loadCatalogConnector();
        props.setEditCatalogDetails({
          ...props.catalogDetails,
          ...{ ...data, connectors: undefined },
        });
      }
    };
    let errorFn = function () {
      setDataActionLoading(false);
    };
    putAPI(
      interpolate(CATALOG_CONNECTOR_UPDATE, [props.id]),
      {
        ...data,
        cost_standard: undefined,
      },
      successFn,
      errorFn
    );
  };
  const setPrimaryDistributor = (id) => {
    setDataActionLoading(true);
    let successFn = function (result) {
      setDataActionLoading(false);
      loadCatalogDistributors();
      props.loadCatalogDetailById(props.id);
    };
    let errorFn = function () {
      setDataActionLoading(false);
    };
    postAPI(
      interpolate(SET_CATALOG_PRIMARY_DISTRIBUTOR, [props.id, id]),
      {},
      successFn,
      errorFn
    );
  };

  useEffect(() => {
    distributorTabKey === 'ACTIVE'
      ? loadCatalogDistributors({ is_active: true }, { sortBy: 'order' })
      : loadCatalogDistributors(
          { is_active: false },
          { sortBy: 'distributor_name', sorDir: 'ASC' }
        );
    loadCatalogLabor();
    loadCatalogConnector();
  }, [props.id]);

  const calculateValuesForItemPriceForm = (delay = 500, type = true) => {
    setTimeout(function () {
      let values = { ...itemPriceFormRefs.current.getFieldsValue() };
      if (!values.cost) {
        return;
      }
      if (type === 'markUpPercent') {
        if (!isNaN(values.markup_percent)) {
          values.markup_percent = values.markup_percent || 0;
          values.markup_amount = (
            values.cost *
            (values.markup_percent / 100)
          ).toFixed(2);
        }
      } else if (type === 'markUpAmount') {
        if (!isNaN(values.markup_amount)) {
          values.markup_amount = values.markup_amount || 0;
          values.markup_percent = (
            (values.markup_amount / values.cost) *
            100
          ).toFixed(2);
        }
      } else {
        values.markup_amount = values.markup_amount || 0;
        values.markup_percent = (
          (values.markup_amount / values.cost) *
          100
        ).toFixed(2);
      }
      if (!values.use_fixed_price)
        values.price = +(
          values.cost +
          values.cost * (values.markup_percent / 100)
        ).toFixed(2);
      itemPriceFormRefs.current.setFieldsValue({ ...values });
    }, delay);
  };

  const renderTabs = () => {
    const items = [
      {
        key: 'DISTRIBUTOR',
        label: 'Distributors',
        children: (
          <EditCatalogDistributorTab
            loadCatalogDistributors={loadCatalogDistributors}
            apiLoader={apiLoader}
            distributorData={distributorData}
            onSortEnd={onSortEnd}
            roleCode={roleCode}
            toggleEditDistributorModal={toggleEditDistributorModal}
            catalogDetails={props.catalogDetails}
            updateDistributorData={updateDistributorData}
            distributorTabKey={distributorTabKey}
            setDistributorTabKey={setDistributorTabKey}
          />
        ),
      },
      {
        key: 'PRICING',
        label: 'Connectors',
        children: (
          <EditCatalogPricingTab
            apiLoader={apiLoader}
            connectorData={connectorData}
            roleCode={roleCode}
            toggleEditConnectorModal={toggleEditConnectorModal}
            laborData={laborData}
            setShowPartError={setShowPartError}
            calculateValuesForItemPriceForm={calculateValuesForItemPriceForm}
            updateConnectorsState={updateConnectorsState}
          />
        ),
      },
      {
        key: 'LABOR',
        label: 'Labor',
        children: (
          <EditCatalogLaborTab
            apiLoader={apiLoader}
            laborData={laborData}
            updateLabor={updateLabor}
          />
        ),
      },
    ];

    return items;
  };

  return (
    <Row>
      <Col span={24}>
        <Tabs
          type='card'
          className={'system-config-tabs'}
          destroyInactiveTabPane={true}
          items={renderTabs()}
        />
      </Col>
      <ModalCom
        className='edit-cataloge'
        title={
          <>
            <h3>Connector Item Details</h3>
            <h3 style={{ fontWeight: 500, color: '#aaa' }}>
              {props?.catalogDetails?.spn}
            </h3>
          </>
        }
        footer={null}
        open={_get(editConnectorModal, 'id')}
        key={_get(editConnectorModal, 'id')}
        onCancel={() => toggleEditConnectorModal(null)}
      >
        <Form
          initialValues={{
            ...editConnectorModal,
            cost: _get(props, 'catalogDetails.cost_standard'),
            item_master_sync_connector_id: _get(
              editConnectorModal,
              'sync_connector_id'
            ),
          }}
          layout={'vertical'}
          key={_get(editConnectorModal, 'id')}
          ref={itemPriceFormRefs}
          onValuesChange={({ connector_part_number }) => {
            if (
              showPartError &&
              connector_part_number?.length <=
                get(
                  editConnectorModal,
                  'SyncConnector.SyncConnectorType.max_spn_length'
                )
            ) {
              setShowPartError(false);
            }
          }}
          onFinish={(values) => {
            let data = { ...values };

            delete data['map_to_code'];

            if (
              editConnectorModal.connector_part_number &&
              values.connector_part_number !==
                editConnectorModal.connector_part_number
            ) {
              Modal.confirm({
                content: `CPN will be updated from ${editConnectorModal.connector_part_number} to ${values.connector_part_number} for the Item on the connected system, do you wish to update the CPN?`,
                onOk: () => {
                  updateConnectorsState(
                    {
                      connectors: [
                        {
                          ...data,
                          SyncConnector: undefined,
                          cost: undefined,
                          markup_amount: undefined,
                          markup_percent: +values.markup_percent,
                          // price : values.price ? +(values.price.toFixed(2)) : null
                        },
                      ],
                    },
                    get(values, 'is_active')
                  );
                },
                onCancel: () => {
                  updateConnectorsState(
                    {
                      connectors: [
                        {
                          ...data,
                          connector_part_number:
                            editConnectorModal.connector_part_number,
                          SyncConnector: undefined,
                          cost: undefined,
                          markup_amount: undefined,
                          markup_percent: +values.markup_percent,
                          // price : values.price ? +(values.price.toFixed(2)) : null
                        },
                      ],
                    },
                    get(values, 'is_active')
                  );
                },
                okText: 'Yes',
                cancelText: 'No',
              });
            } else {
              updateConnectorsState(
                {
                  connectors: [
                    {
                      ...data,
                      SyncConnector: undefined,
                      cost: undefined,
                      markup_amount: undefined,
                      markup_percent: +values.markup_percent,
                      // price : values.price ? +(values.price.toFixed(2)) : null
                    },
                  ],
                },
                get(values, 'is_active')
              );
            }
          }}
        >
          <Row gutter={32}>
            <Col span={24}>
              <Form.Item
                label='Connector Part Number'
                name='connector_part_number'
              >
                <Input
                  showCount
                  maxLength={get(
                    editConnectorModal,
                    'SyncConnector.SyncConnectorType.max_spn_length'
                  )}
                  disabled={
                    !get(editConnectorModal, 'SyncConnector.cpn_can_change') &&
                    !!get(editConnectorModal, 'connector_part_number')
                  }
                />
              </Form.Item>
              {showPartError && (
                <p style={{ color: 'red' }}>
                  Part Number exceeds the maximum length for this connector.
                </p>
              )}
            </Col>
            <Col span={24}>
              <Form.Item label='Description' name={'description'}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Sync Connector'
                name={'item_master_sync_connector_id'}
                hidden
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label='Sync Connector'
                name={['SyncConnector', 'name']}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Active'
                name={'is_active'}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Cost' name={'cost'}>
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={calculateValuesForItemPriceForm}
                  disabled={true}
                  step={0.01}
                  className={'right-aligned'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Price'
                name={'price'}
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={calculateValuesForItemPriceForm}
                  className={'right-aligned'}
                  disabled={!_get(editConnectorModal, 'use_fixed_price')}
                  step={'0.01'}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify={'end'} gutter={32}>
                <Col span={12}>
                  <Form.Item name={'use_fixed_price'} valuePropName={'checked'}>
                    <Checkbox
                      onChange={(e) => {
                        toggleEditConnectorModal({
                          ...editConnectorModal,
                          use_fixed_price: !!e.target.checked,
                        });
                        calculateValuesForItemPriceForm();
                      }}
                    >
                      Use Fixed Price
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item label='Markup Percent' name={'markup_percent'}>
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  step={'0.01'}
                  className={'right-aligned'}
                  disabled={
                    _get(editConnectorModal, 'use_default_markup') ||
                    _get(editConnectorModal, 'use_fixed_price')
                  }
                  onChange={() =>
                    calculateValuesForItemPriceForm(2500, 'markUpPercent')
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Markup Amount' name={'markup_amount'}>
                <InputNumber
                  disabled={
                    _get(editConnectorModal, 'use_default_markup') ||
                    _get(editConnectorModal, 'use_fixed_price')
                  }
                  style={{ width: '100%' }}
                  min={0}
                  step={'0.01'}
                  className={'right-aligned'}
                  onChange={() =>
                    calculateValuesForItemPriceForm(2500, 'markUpAmount')
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name={'use_default_markup'}
                    valuePropName={'checked'}
                  >
                    <Checkbox
                      onChange={(e) => {
                        toggleEditConnectorModal({
                          ...editConnectorModal,
                          use_default_markup: !!e.target.checked,
                        });
                        if (e.target.checked)
                          itemPriceFormRefs.current.setFieldsValue({
                            markup_percent: _get(
                              editConnectorModal,
                              'SyncConnector.markup_percent'
                            ),
                          });
                        calculateValuesForItemPriceForm(500, 'markUpPercent');
                      }}
                      disabled={
                        _get(editConnectorModal, 'use_fixed_price') ||
                        roleCode === ROLE_CATALOG_READ_ONLY_USER
                      }
                    >
                      Use Default Markup
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Form.Item
                label={'Map to Code'}
                name={'map_to_code'}
                rules={[
                  {
                    max: 100,
                    message: 'Map to code cannot be more than 100 characters',
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row gutter={16} justify={'end'}>
                {!(roleCode === ROLE_CATALOG_READ_ONLY_USER)
                  ? !showPartError && (
                      <Col>
                        <Button
                          type={'primary'}
                          htmlType={'submit'}
                          onClick={(e) => {
                            e.preventDefault();
                            itemPriceFormRefs.current?.submit();
                          }}
                          loading={dataActionLoading}
                        >
                          Ok
                        </Button>
                      </Col>
                    )
                  : null}
                <Col>
                  <Button
                    disabled={false}
                    type={'secondary'}
                    onClick={() => toggleEditConnectorModal(null)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      <ModalCom
        footer={null}
        open={_get(editDistributorModal, 'id')}
        key={_get(editDistributorModal, 'id')}
        title='Edit Distributor'
        onCancel={() => toggleEditDistributorModal(null)}
      >
        <Form
          initialValues={{
            ...editDistributorModal,
            description:
              _get(editDistributorModal, 'distributor_id', '-') === 999999999
                ? _get(props, 'catalogDetails.description')
                : _get(editDistributorModal, 'description'),
          }}
          layout={'vertical'}
          key={_get(editDistributorModal, 'id')}
          ref={itemDistributorFormRef}
          onFinish={(values) =>
            updateDistributorData({
              ...values,
              item_master_distributor_id: _get(editDistributorModal, 'id'),
              current_cost: +values.current_cost || 0.0,
              msrp: +values.msrp || 0.0,
              rmr: +values.rmr || 0.0,
              rmr_frequency: values.rmr_frequency || '0',
              Distributor: undefined,
            })
          }
        >
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label='Distributor' name={['Distributor', 'name']}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Cost' name={'current_cost'}>
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.01}
                  className={'right-aligned'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Discontinued'
                name={'discontinued'}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Active'
                name={'is_active'}
                valuePropName={'checked'}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='MSRP' name={'msrp'}>
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.01}
                  className={'right-aligned'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='RMR' name={'rmr'}>
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.01}
                  className={'right-aligned'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='RMR Frequency' name={'rmr_frequency'}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Description' name={'description'}>
                <Input.TextArea disabled={true} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row gutter={16} justify={'end'}>
                {!(roleCode === ROLE_CATALOG_READ_ONLY_USER) ? (
                  <Col>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      onClick={(e) => {
                        e.preventDefault();
                        itemDistributorFormRef.current?.submit();
                      }}
                      loading={dataActionLoading}
                    >
                      Ok
                    </Button>
                  </Col>
                ) : null}
                <Col>
                  <Button
                    disabled={false}
                    type={'secondary'}
                    onClick={() => toggleEditDistributorModal(null)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </ModalCom>
    </Row>
  );
}

export default EditCatalogExtraData;
