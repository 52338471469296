import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  CATEGORY,
  DISTRIBUTOR,
  LOGS,
  MANUFACTURER,
  MANUFACTURER_IN_USE_STATE,
  MANUFACTURER_MAPPED_STATE,
} from '../../constants/constants';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSyncConnectorIntegrationsData,
  setSyncConnectorCategoryData,
} from '../../redux/Slices/systemSyncConnectorIntegrationSlice';
import { isEmpty, lowerCase } from 'lodash';
import { prohibitDecimalEntry } from '../../utils/common';
import {
  SyncOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { useParams, useSearchParams } from 'react-router-dom';
import { filterTreeData } from '../../utils/helper';
import {
  clearFormObj,
  updateFilterFields,
  updateQueryParamObj,
} from '../modules/system/commonKeyIntegrationMethods';
import dayjs from 'dayjs';

const marginSpace = {
  marginLeft: '24px',
};

export const IntegrationFilters = forwardRef(({ tableSource, id }, ref) => {
  const [integrationFiltersForm] = Form.useForm();
  const dispatch = useDispatch();
  const { tab } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const originCategoryData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.originCategoryData
  );
  const logsData = useSelector(
    (state) => state.systemSyncConnectorIntegrationReducer.logsData
  );

  const initialValues = {
    searchVal: searchParams.get('name') ? searchParams.get('name') : '',
    manufaturerMappedState: searchParams.get('mappingstatus')
      ? updateFilterFields(
          'manufaturerMappedState',
          searchParams.get('mappingstatus')
        )
      : '',
    manufacturerInUse: searchParams.get('inuse')
      ? updateFilterFields('manufacturerInUse', searchParams.get('inuse'))
      : MANUFACTURER_IN_USE_STATE.IN_USE,
    status: searchParams.get('status') ? searchParams.get('status') : '',
    item_id: searchParams.get('itemid') ? searchParams.get('itemid') : '',
    connector_part_number: searchParams.get('cpn')
      ? searchParams.get('cpn')
      : '',
    create_date: searchParams.get('date')
      ? dayjs(searchParams.get('date'), 'YYYY-MM-DD')
      : '',
  };

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      integrationFiltersForm.resetFields();
    },
    resetFields: () => {
      setTimeout(() => {
        integrationFiltersForm.setFieldsValue({ searchVal: '' });
      }, 1000);
    },
    getSearchField: () => {
      return integrationFiltersForm.getFieldValue('searchVal');
    },
  }));

  const manufacturerAddedFilters = () => {
    return (
      <>
        <Col>
          <Form.Item name={'manufaturerMappedState'}>
            <Select
              placeholder='Mapping Status'
              options={Object.values(MANUFACTURER_MAPPED_STATE).map((item) => ({
                value: item,
                label: item,
              }))}
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'manufacturerInUse'}>
            <Select
              placeholder=''
              options={Object.values(MANUFACTURER_IN_USE_STATE).map((item) => ({
                value: item,
                label: item,
              }))}
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
      </>
    );
  };

  const logsFilters = () => {
    return (
      <Row>
        <Col>
          <Form.Item name={'status'}>
            <Select
              placeholder='Status'
              style={{ width: '150px' }}
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'success',
                  label: (
                    <>
                      <CheckCircleFilled style={{ color: 'green' }} />
                      &nbsp;Success
                    </>
                  ),
                },
                {
                  value: 'failure',
                  label: (
                    <>
                      <ExclamationCircleFilled style={{ color: 'red' }} />
                      &nbsp;Failure
                    </>
                  ),
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'item_id'}>
            <Input
              className='remove-side-arrows'
              placeholder='Item Id'
              type='number'
              onKeyDown={(evt) => prohibitDecimalEntry(evt)}
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'connector_part_number'}>
            <Input
              placeholder='CPN'
              style={{ width: '150px', ...marginSpace }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'create_date'}>
            <DatePicker
              style={{ width: 200, ...marginSpace }}
              placeholder='Date'
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const updateData = (filteredData) => {
    dispatch(
      getSyncConnectorIntegrationsData(lowerCase(tableSource), id, undefined, {
        filters: { ...filteredData },
      })
    );
  };

  const updateQueryParams = (qp) => {
    const updatedParams = new URLSearchParams();

    for (const [key, value] of Object.entries(qp)) {
      updatedParams.set(key, value);
    }

    setSearchParams(updatedParams);
  };

  const submitFilters = (values) => {
    if (tableSource === CATEGORY) {
      let arr = filterTreeData(originCategoryData, values.searchVal, 'name');
      values.searchVal
        ? updateQueryParams({ name: values.searchVal })
        : updateQueryParams({});
      dispatch(setSyncConnectorCategoryData(arr));
      return;
    } else {
      const keys = Object.keys(values);
      let filteredData = {};
      let qp = {};
      keys.forEach((key) => {
        qp = updateQueryParamObj(key, values[key], qp);
        if (values[key]) {
          switch (key) {
            case 'create_date':
              filteredData = {
                ...filteredData,
                [key]: moment(values[key]['$d']).format('YYYY-MM-DD'),
              };
              break;
            case 'status':
              if (values[key] !== 'all') {
                filteredData = {
                  ...filteredData,
                  [key]: values[key] === 'success' ? true : false,
                };
              }
              break;
            case 'item_id':
              filteredData = {
                ...filteredData,
                [key]: parseInt(values[key]),
              };
              break;
            case 'searchVal':
              if (tableSource === MANUFACTURER) {
                filteredData = {
                  ...filteredData,
                  description: values[key],
                };
              } else if (tableSource === DISTRIBUTOR) {
                filteredData = {
                  ...filteredData,
                  name: values[key],
                };
              }
              break;
            case 'manufaturerMappedState':
              if (values[key] !== MANUFACTURER_MAPPED_STATE.ALL) {
                filteredData = {
                  ...filteredData,
                  maping_status:
                    values[key] === MANUFACTURER_MAPPED_STATE.MAPPED
                      ? true
                      : false,
                };
              }
              break;
            case 'manufacturerInUse':
              if (values[key] === MANUFACTURER_IN_USE_STATE.ALL) {
                filteredData = {
                  ...filteredData,
                };
              } else {
                filteredData = {
                  ...filteredData,
                  in_use:
                    values[key] === MANUFACTURER_IN_USE_STATE.IN_USE
                      ? true
                      : false,
                };
              }
              break;
            default:
              filteredData = {
                ...filteredData,
                [key]: values[key],
              };
              break;
          }
        }
      });
      updateQueryParams(qp);
      updateData(filteredData);
    }
  };

  const logsRefreshBtn = () => {
    return (
      <Col>
        <Button
          type={'secondary'}
          icon={<SyncOutlined />}
          onClick={() => {
            dispatch(
              getSyncConnectorIntegrationsData(
                lowerCase(tableSource),
                id,
                undefined,
                {
                  filters: { ...logsData.filters },
                }
              )
            );
          }}
          style={{ ...marginSpace }}
        >
          Refresh
        </Button>
      </Col>
    );
  };

  const clearFiltersBtn = () => {
    return (
      <Col>
        <Form.Item labelCol={{ span: 8 }}>
          <Button
            type={'secondary'}
            style={{ ...marginSpace }}
            onClick={() => {
              integrationFiltersForm.setFieldsValue(clearFormObj(tableSource));

              if (tableSource === MANUFACTURER) {
                updateData({ in_use: true });
                updateQueryParams({ inuse: 'true' });
              } else {
                updateQueryParams({});
                updateData({});
              }
            }}
          >
            Clear
          </Button>
        </Form.Item>
      </Col>
    );
  };

  useEffect(() => {
    if (
      tab === lowerCase(MANUFACTURER) &&
      isEmpty(Object.fromEntries(searchParams.entries()))
    ) {
      console.log(Object.fromEntries(searchParams.entries()));
      updateQueryParams({ inuse: 'true' });
    }
  }, [tab]);

  return (
    <>
      <Row
        justify={'end'}
        style={{ backgroundColor: '#f4f4f4', padding: '8px' }}
      >
        <Col span={24}>
          <Form
            layout='vertical'
            className={'filter-form'}
            form={integrationFiltersForm}
            onFinish={submitFilters}
            initialValues={initialValues}
          >
            <Row>
              <Col>
                {tableSource !== LOGS && (
                  <Form.Item name={'searchVal'}>
                    <Input
                      style={{ width: '190px' }}
                      placeholder={
                        tableSource === MANUFACTURER
                          ? 'Name'
                          : `Search Blackleaf ${tableSource}`
                      }
                    />
                  </Form.Item>
                )}
              </Col>
              {tableSource === MANUFACTURER && manufacturerAddedFilters()}
              <Col>{tableSource === LOGS && logsFilters()}</Col>
              <Col>
                <Form.Item labelCol={{ span: 8 }}>
                  <Button
                    type={'primary'}
                    htmlType='submit'
                    style={{ ...marginSpace }}
                  >
                    Apply
                  </Button>
                </Form.Item>
              </Col>
              {(tableSource === DISTRIBUTOR || tableSource === MANUFACTURER) &&
                clearFiltersBtn()}
              {tableSource === LOGS && logsRefreshBtn()}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
});
