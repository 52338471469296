import { createSlice } from '@reduxjs/toolkit';
import { PRODUCT_CATEGORY_LABOR_CATEGORY } from '../reduxConstant';
import { changeApiLoader } from './apiLoaderSlice';
import { getAPI } from '../../utils/apiRequest';
import { PRODUCT_CATEGORY_LABOR_CATEGORY_MAPPING_LIST } from '../../constants/api';
import { interpolate } from '../../utils/common';

export const productCatLaborCatSlice = createSlice({
  name: PRODUCT_CATEGORY_LABOR_CATEGORY,
  initialState: {
    data: [],
    copiedData: [],
  },
  reducers: {
    setProductCatLaborCatMappingData: (state, action) => {
      state.data = action.payload;
    },
    setCopiedProductCatLaborCatMappingData: (state, action) => {
      state.copiedData = action.payload;
    },
  },
});

export const getProductCatLaborCatMappingData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = (results) => {
    dispatch(setProductCatLaborCatMappingData(results.data));
    dispatch(setCopiedProductCatLaborCatMappingData(results.data));
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  getAPI(
    interpolate(PRODUCT_CATEGORY_LABOR_CATEGORY_MAPPING_LIST, [id]),
    successFn,
    errorFn
  );
};

export const {
  setProductCatLaborCatMappingData,
  setCopiedProductCatLaborCatMappingData,
} = productCatLaborCatSlice.actions;

export default productCatLaborCatSlice.reducer;
