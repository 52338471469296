import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HistoryOutlined,
  PlusOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import {
  API_KEY_CREATE_DATA,
  API_KEY_LIST,
  API_KEY_SINGLE_DATA,
  API_KEY_SINGLE_REFRESH,
} from '../../../constants/api';
import { deleteAPI, patchAPI, postAPI } from '../../../utils/apiRequest';
import { copyToClipboard, interpolate } from '../../../utils/common';
import {
  ACTIVE_INACTIVE_FILTER_LIST,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import ModalCom from '../../common/Modal';

const { Title, Paragraph } = Typography;

function APIKeyManagement(props) {
  const [toggleAddAPIKeyModal, setToggleAddAPIKeyModal] = useState(false);
  const [toggleEditAPIKeyModal, setToggleEditAPIKeyModal] = useState(false);
  const [toggleRefreshAPIKeyModal, setToggleRefreshAPIKeyModal] =
    useState(false);
  const [toggleGeneratedAPIKeyModal, setToggleGeneratedAPIKeyModal] =
    useState(false);
  const [apiKeyData, setAPIKeyData] = useState({});
  const [apiKeyDataLoading, toggleAPIKeyDataLoading] = useState(false);
  const [apiKeyDataActionLoading, toggleAPIKeyDataActionLoading] =
    useState(false);
  const tablePaginationParams = useSelector(
    (state) =>
      _get(state, 'global.globalPagesPagination')[PAGES.SYSTEM_API_KEYS]
  );
  const toggleFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterToggle')[PAGES.SYSTEM_API_KEYS]
  );
  const activeFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterData')[PAGES.SYSTEM_API_KEYS]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(
          PAGES.SYSTEM_API_KEYS,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.SYSTEM_API_KEYS, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterToggle(PAGES.SYSTEM_API_KEYS, param(toggleFilters))
      );
    } else {
      dispatch(globalPagesFilterToggle(PAGES.SYSTEM_API_KEYS, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterData(PAGES.SYSTEM_API_KEYS, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.SYSTEM_API_KEYS, param));
    }
  };

  useEffect(() => {
    loadAPIKeysData();
  }, [tablePaginationParams]);

  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 110,
      render: (value, record) => (
        <Space>
          <Tooltip title={'Edit'}>
            <EditOutlined onClick={() => setToggleEditAPIKeyModal(record)} />
          </Tooltip>
          <Tooltip title={'Refresh'}>
            <HistoryOutlined
              onClick={() => setToggleRefreshAPIKeyModal(record)}
              disabled={apiKeyDataActionLoading}
            />
          </Tooltip>
          <Tooltip title={'Copy'}>
            <CopyOutlined onClick={() => copyToClipboard(record.token)} />
          </Tooltip>
          <Tooltip title={'Delete'}>
            <Popconfirm
              title={'Are you sure you want to delete this API Key?'}
              onConfirm={() => deleteAPIKey(record.id)}
            >
              <DeleteOutlined disabled={apiKeyDataActionLoading} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortOrder:
        _get(apiKeyData, 'sortBy') === 'name'
          ? _get(apiKeyData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Token',
      key: 'token',
      dataIndex: 'token',
      width: 700,
      render: (value, record) => (
        <div style={{ maxWidth: 700 }}>
          <Paragraph>{value}</Paragraph>
        </div>
      ),
    },
    {
      title: 'Valid Till',
      key: 'expires_at',
      dataIndex: 'expires_at',
      sorter: true,
      sortOrder:
        _get(apiKeyData, 'sortBy') === 'expires_at'
          ? _get(apiKeyData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'is_active',
      width: 100,
      render: (value, record) => (
        <Switch
          checked={value}
          onClick={() => updateAPIKey(record.id, { is_active: !value })}
          disabled={apiKeyDataActionLoading}
        />
      ),
    },
  ];
  const loadAPIKeysData = () => {
    toggleAPIKeyDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setAPIKeyData(result);
      toggleAPIKeyDataLoading(false);
    };
    let errorFn = function () {
      toggleAPIKeyDataLoading(false);
    };
    postAPI(API_KEY_LIST, params, successFn, errorFn);
  };
  const deleteAPIKey = (id) => {
    toggleAPIKeyDataActionLoading(true);
    let successFn = function () {
      toggleAPIKeyDataActionLoading(false);
      setToggleAddAPIKeyModal(false);
      loadAPIKeysData();
    };
    let errorFn = function () {
      toggleAPIKeyDataActionLoading(false);
    };
    deleteAPI(interpolate(API_KEY_SINGLE_DATA, [id]), successFn, errorFn);
  };
  const refreshAPIKey = (id, data) => {
    toggleAPIKeyDataActionLoading(true);
    let successFn = function () {
      toggleAPIKeyDataActionLoading(false);
      setToggleAddAPIKeyModal(false);
      setToggleRefreshAPIKeyModal(false);
      loadAPIKeysData();
    };
    let errorFn = function () {
      toggleAPIKeyDataActionLoading(false);
    };
    postAPI(
      interpolate(API_KEY_SINGLE_REFRESH, [id]),
      data,
      successFn,
      errorFn
    );
  };
  const addAPIKey = (data) => {
    let reqData = {
      name: data.name,
      expires_at: moment(data.expires_at).valueOf(),
      is_active: true,
    };
    toggleAPIKeyDataActionLoading(true);
    let successFn = function (result) {
      setToggleGeneratedAPIKeyModal(result.data);
      toggleAPIKeyDataActionLoading(false);
      setToggleAddAPIKeyModal(false);
      loadAPIKeysData();
    };
    let errorFn = function () {
      toggleAPIKeyDataActionLoading(false);
    };
    postAPI(API_KEY_CREATE_DATA, reqData, successFn, errorFn);
  };
  const updateAPIKey = (id, data) => {
    toggleAPIKeyDataActionLoading(true);
    let successFn = function () {
      toggleAPIKeyDataActionLoading(false);
      setToggleEditAPIKeyModal(false);
      loadAPIKeysData();
    };
    let errorFn = function () {
      toggleAPIKeyDataActionLoading(false);
    };
    patchAPI(interpolate(API_KEY_SINGLE_DATA, [id]), data, successFn, errorFn);
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };
  return (
    <>
      <Title className={'page-title'} level={2}>
        API Keys
      </Title>
      <Table
        className='data-table full-height-table'
        size={'small'}
        scroll={{ y: 'calc(100vh - 500px)' }}
        pagination={false}
        rowKey={'id'}
        dataSource={_get(apiKeyData, 'data', [])}
        onChange={(a, b, sorting) =>
          setTableSortingParams(sorting.field, sorting.order)
        }
        columns={tableColumns}
        showSorterTooltip={false}
        loading={apiKeyDataLoading}
        title={() => (
          <Row justify={'end'}>
            <Col>
              <Button
                size={'large'}
                onClick={() => setToggleFilters(!toggleFilters)}
              >
                {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
              </Button>
              <Button
                size={'large'}
                type={'primary'}
                icon={<PlusOutlined />}
                className='btn-green'
                onClick={() => setToggleAddAPIKeyModal(true)}
              >
                Add API Key
              </Button>
            </Col>
            {toggleFilters && (
              <Col span={24}>
                <Form
                  className='filter-form'
                  onFinish={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset: 0,
                      filters: { ...activeFilters },
                    })
                  }
                >
                  <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                    <Input
                      value={activeFilters.name}
                      placeholder='Name'
                      onChange={(e) =>
                        setActiveFilters((prev) => {
                          return { ...prev, name: e.target.value };
                        })
                      }
                    />
                    {/*<DatePicker value={activeFilters.expires_at ? moment(activeFilters.expires_at,'YYYY-MM-DD') : null} placeholder={'Valid Till'} style={{ width: 200 }}  onChange={(value) => setActiveFilters(prev => {*/}
                    {/*    return { ...prev, expires_at: value ? moment(value).format("YYYY-MM-DD") : undefined };*/}
                    {/*})}/>*/}
                    <Select
                      optionFilterProp='children'
                      showSearch
                      value={activeFilters.is_active}
                      placeholder={'Status'}
                      style={{ width: 200 }}
                      allowClear
                      onChange={(value) =>
                        setActiveFilters((prev) => {
                          return { ...prev, is_active: value };
                        })
                      }
                    >
                      {ACTIVE_INACTIVE_FILTER_LIST.map((item) => (
                        <Select.Option value={item.value} key={item.label}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        Apply
                      </Button>
                    </Form.Item>
                    <Button
                      type={'secondary'}
                      onClick={() => {
                        setActiveFilters({});
                        setTablePaginationParams({
                          ...tablePaginationParams,
                          filters: {},
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form>
              </Col>
            )}
          </Row>
        )}
        footer={() => (
          <Row justify={'space-between'}>
            <Col>
              <Space>
                <Dropdown
                  menu={{ items: setDropDownItems() }}
                  trigger={['click']}
                >
                  <Button type='ghost'>
                    Items Per Page:{_get(tablePaginationParams, 'limit')}
                    <DownOutlined className={'ml-5'} />
                  </Button>
                </Dropdown>
                <Typography>{`${_get(apiKeyData, 'offset', 0) + 1} to ${
                  _get(apiKeyData, 'data', []).length +
                  _get(apiKeyData, 'offset', '--')
                } of ${_get(apiKeyData, 'total', '--')} items`}</Typography>
              </Space>
            </Col>
            <Col>
              <Button.Group>
                <Button
                  type='ghost'
                  icon={<CaretLeftOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(apiKeyData, 'offset', 0) -
                        _get(apiKeyData, 'limit', 0),
                    })
                  }
                  disabled={!_get(apiKeyData, 'offset', 0)}
                />
                <Button
                  type='ghost'
                  icon={<CaretRightOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(apiKeyData, 'offset', 0) +
                        _get(apiKeyData, 'limit', 0),
                    })
                  }
                  disabled={
                    _get(apiKeyData, 'offset', 0) +
                      _get(apiKeyData, 'limit', 0) >=
                    _get(apiKeyData, 'total', 0)
                  }
                />
              </Button.Group>
            </Col>
          </Row>
        )}
      />
      <ModalCom
        open={toggleAddAPIKeyModal}
        title={'Add New Key'}
        key={toggleAddAPIKeyModal}
        onCancel={() => setToggleAddAPIKeyModal(false)}
        footer={null}
      >
        <Form layout='vertical' key={toggleAddAPIKeyModal} onFinish={addAPIKey}>
          <Row gutter={16}>
            <Col span={13}>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                  {
                    min: 3,
                    max: 100,
                    message: 'Name must be 3 to 100 characters long',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={13}>
              <Form.Item
                label='Valid Till'
                name='expires_at'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD-MM-YYYY'}
                  disabledDate={(current) => {
                    // Can not select days before today
                    return current < moment().endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={apiKeyDataActionLoading}
                  className='btn-green'
                >
                  {' '}
                  Add{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => setToggleAddAPIKeyModal(false)}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      <ModalCom
        open={toggleGeneratedAPIKeyModal}
        title={'API key successfully created'}
        onCancel={() => setToggleGeneratedAPIKeyModal(false)}
        footer={null}
      >
        <Input
          disabled
          value={_get(toggleGeneratedAPIKeyModal, 'token', '--')}
        />
        <span>This is your unique API key.</span>
        <Row gutter={16} justify={'end'}>
          <Col>
            <Form.Item>
              <Button
                type='primary'
                icon={<CopyOutlined />}
                onClick={() =>
                  copyToClipboard(
                    _get(toggleGeneratedAPIKeyModal, 'token', '--')
                  )
                }
              >
                {' '}
                Copy API Key{' '}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type='secondary'
              onClick={() => setToggleGeneratedAPIKeyModal(false)}
            >
              {' '}
              Close
            </Button>
          </Col>
        </Row>
      </ModalCom>
      <ModalCom
        open={_get(toggleEditAPIKeyModal, 'id')}
        title={'Edit Key'}
        key={_get(toggleEditAPIKeyModal, 'id')}
        onCancel={() => setToggleEditAPIKeyModal(false)}
        footer={null}
      >
        <Form
          layout='vertical'
          key={_get(toggleEditAPIKeyModal, 'id')}
          initialValues={{
            ...toggleEditAPIKeyModal,
            expires_at: moment(_get(toggleEditAPIKeyModal, 'expires_at')),
          }}
          onFinish={(values) =>
            updateAPIKey(_get(toggleEditAPIKeyModal, 'id'), {
              ...values,
              name: undefined,
              expires_at: undefined,
            })
          }
        >
          <Row gutter={16}>
            <Col span={13}>
              <Form.Item label='Name' name='name'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Valid Till'
                name='expires_at'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD-MM-YYYY'}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Active'
                name='is_active'
                valuePropName='checked'
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={apiKeyDataActionLoading}
                >
                  {' '}
                  Update{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => setToggleEditAPIKeyModal(false)}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      <ModalCom
        open={_get(toggleRefreshAPIKeyModal, 'id')}
        title={'Refresh Key'}
        key={_get(toggleRefreshAPIKeyModal, 'id')}
        onCancel={() => setToggleRefreshAPIKeyModal(false)}
        footer={null}
      >
        <Form
          layout='vertical'
          key={_get(toggleRefreshAPIKeyModal, 'id')}
          initialValues={{
            ...toggleRefreshAPIKeyModal,
            expires_at: moment(_get(toggleRefreshAPIKeyModal, 'expires_at')),
          }}
          onFinish={(values) =>
            refreshAPIKey(_get(toggleRefreshAPIKeyModal, 'id'), {
              ...values,
              name: undefined,
              expires_at: moment(values.expires_at).endOf('day').valueOf(),
            })
          }
        >
          <Row gutter={16}>
            <Col span={13}>
              <Form.Item label='Name' name='name'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Valid Till'
                name='expires_at'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD-MM-YYYY'}
                  disabledDate={(current) => {
                    // Can not select days before today
                    return current < moment().endOf('day');
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={apiKeyDataActionLoading}
                >
                  {' '}
                  Refresh{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => setToggleRefreshAPIKeyModal(false)}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
    </>
  );
}

export default APIKeyManagement;
